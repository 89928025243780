export default {
  locale: "th",
  language_thai: 'ภาษาไทย',
  language_english: 'English',
  pointspot: "Pointspot",
  hello: "สวัสดี!",
  slide_login: "เลื่อนขวา เพื่อเข้าสู่ระบบ",
  login: "เข้าสู่ระบบ",
  difference_mobile: "เข้าสู่ระบบด้วยหมายเลขอื่น",
  mobile_number: "เบอร์โทรศัพท์มือถือ",
  otp_sending: "กำลังส่งรหัส OTP ...",
  otp_verify: "กำลังตรวจสอบ OTP ...",
  resend_otp: "ส่งรหัสอีกครั้งทาง SMS",
  request_otp: "ขอรหัส OTP ใหม่",
  submit_otp: "ยืนยันรหัส",
  enter_otp: "ใส่รหัส OTP ที่นี่",
  accept_terms: "การล็อคอินเข้าสู่ Pointspot ท่านยอมรับ",
  create_loyalty_program: "ต้องการเพิ่มลูกค้าประจำ? สมัคร Pointspot สำหรับธุรกิจ",
  create_loyalty_program1: "ต้องการเพิ่มลูกค้าประจำ?",
  create_loyalty_program2: "สมัคร Pointspot สำหรับธุรกิจ",
  loyalty_program: 'ระบบบัตรสมาชิกสะสมแต้มด้วยเบอร์โทร',
  male: 'ชาย',
  female: 'หญิง',
  unspecified: 'ไม่ระบุ',
  create_pointspot_user_account: 'สร้างบัญชีผู้ใช้ Pointspot',
  accept_terms_conditions: 'ยอมรับเงื่อนไขการใช้บริการ',
  date: 'วัน',
  dates: 'วันที่',
  month: 'เดือน',
  year: 'ปี',
  birthday_month: 'เดือนเกิด',
  January: 'มกราคม',
  February: 'กุมภาพันธ์',
  March: 'มีนาคม',
  April: 'เมษายน',
  May: 'พฤษภาคม',
  June: 'มิถุนายน',
  July: 'กรกฏาคม',
  August: 'สิงหาคม',
  September: 'กันยายน',
  October: 'ตุลาคม',
  November: 'พฤศจิกายน',
  December: 'ธันวาคม',
  first_name: 'ชื่อ',
  last_name: 'นามสกุล',
  logout: 'ออกจากระบบ',
  submit: 'ยืนยัน',
  skip: 'ข้าม',
  ok: 'ตกลง',
  cancel: 'ยกเลิก',
  cancelled: 'ยกเลิกแล้ว',
  logout_question: 'คุณต้องการออกจากระบบ ?',
  sms_notifications: 'เปิดแจ้งเตือน',
  disable_sms_notifications: 'ปิดแจ้งเตือน',
  successfully: 'เรียบร้อย',
  saved: 'บันทึกข้อมูลเรียบร้อย',
  gender: 'เพศ',
  email: 'อีเมล',
  save: 'บันทึก',
  pointspot_id: 'Pointspot ID',
  load_more: 'ดูเพิ่มเติม',
  home_page: 'หน้าแรก',
  other: 'อื่นๆ',
  rewards: 'รางวัลสะสมแต้ม',
  recent_transaction: 'รายการล่าสุด',
  transaction_detail: 'รายละเอียดรายการ',
  attached_image: 'รูปภาพ',
  usage_history: 'ประวัติการใช้งาน',
  recent_activity: 'กิจกรรมล่าสุด',
  terms_and_policy: 'เงื่อนไขการใช้บริการและนโยบาย',
  terms_conditions: 'เงื่อนไขการใช้บริการ',
  no_terms_conditions: 'ไม่พบเงื่อนไขการใช้บริการ',
  conditions: 'เงื่อนไข',
  no_transaction: 'ไม่มีรายการ',
  no_activity: 'ไม่มีกิจกรรม',
  points: 'พอยท์',
  extra_points: "พอยท์พิเศษ",
  total_points: 'พอยท์สะสม',
  points_cards: 'บัตรสะสมพอยท์',
  loyalty_accounts: 'บัญชีสะสมพอยท์',
  transfer: 'โอน',
  pay: 'จ่าย',
  transfer_p2p: 'โอนให้เพื่อน',
  transfer_to_business: 'จ่ายด้วยพอยท์',
  invalid_receiver: 'ข้อมูลผู้รับไม่ถูกต้อง',
  can_not_transfer_to_yourself: 'ไม่สามารถโอนพอยท์ให้ตัวเองได้',
  business_name: 'ชื่อธุรกิจ',
  activity: 'รายการ',
  my_points: 'พอยท์ของฉัน',
  scan_qr_code: 'สแกน QR Code',
  profile: 'โปรไฟล์',
  qr_code_support: 'QR Scanner รองรับ iOS 11 หรือใหม่กว่า',
  enter_refernce_code: 'กรอกโค้ดหรือสแกน QR code บน safari browser',
  mobile_or_id: 'เบอร์โทรศัพท์ หรือ Pointspot ID',
  remark: 'หมายเหตุ',
  send: 'ส่ง',
  receiver_not_found: 'ไม่พบบัญชีปลายทาง',
  redeem: 'แลกพอยท์',
  redeem_question: 'ยืนยันการแลกของรางวัล',
  use_question: 'ยืนยันการใช้งานคูปอง',
  redeem_title: 'คูปอง',
  redeem_code: 'รหัสคูปอง',
  coupon_code: 'รหัสคูปอง',
  coupon_code_reward: 'รหัสคูปองประเภทของรางวัล',
  coupon_code_discount: 'รหัสคูปองประเภทส่วนลด',
  redeem_code_reference: 'รหัสคูปอง',
  redeem_code_activated: 'รหัสคูปองถูกใช้งานแล้ว',
  redeemed_successfully: 'รหัสคูปอง',
  giftvoucher_code: 'รหัสบัตรกำนัล',
  please_show_code: 'กรุณาแสดงรหัสกับเจ้าหน้าที่ เพื่อรับของรางวัล',
  please_swipe: 'สไลด์เพื่อรับสิทธิ (สำหรับเจ้าหน้าที่เท่านั้น)',
  for_admin_only: 'สำหรับเจ้าหน้าที่เท่านั้น',
  use_later: 'ใช้ภายหลัง',
  next: 'ต่อไป',
  get_points: 'รับพอยท์',
  you_received: 'คุณได้รับ',
  transfer_successful: 'โอนพอยท์สำเร็จ',
  pay_points_successful: 'จ่ายด้วยพอยท์สำเร็จ',
  close: 'ปิด',
  copy: 'คัดลอก',
  copied: 'คัดลอกสำเร็จ',
  copy_message: 'คัดลอกข้อความ',
  share_message_to_friend: 'แชร์ข้อความนี้ให้เพื่อน',
  edit: 'แก้ไข',
  edit_profile: 'แก้ไขโปรไฟล์',
  from: 'จาก',
  to: 'ถึง',
  PT: 'พอยท์',
  mycoupon: 'คูปองของฉัน',
  subscribe: 'สมัครสมาชิก',
  unsubscribe: 'เลิกติดตาม',
  mycoupons: 'คูปองของฉัน',
  status: 'สถานะ',
  privacy_policy: 'นโยบายข้อมูลส่วนบุคคล',
  business_privacy_policy: 'นโยบายข้อมูลส่วนบุคคลของ $1',
  accept: 'ยอมรับ',
  permissions: 'สิทธิ์การอนุญาต',
  hide: 'ซ่อนธุรกิจ',
  setting: 'ตั้งค่า',
  confirm_unsubscribe: 'คุณต้องการเลิกติดตามธุรกิจ',
  confirm_hide: 'คุณต้องการซ่อนธุรกิจ',
  or_not: 'หรือไม่ ?',
  qr_code: 'QR Code',
  my_qr_code: 'QR Code ของฉัน',
  show_my_qr_code: 'แสดง QR Code ของฉัน',
  business_setting: 'ตั้งค่าธุรกิจ',
  business_unsubscribe: 'ธุรกิจที่เลิกติดตาม',
  business_unfollow_info:
    'เมื่อคุณเลิกติดตามธุรกิจนั้น คุณจะไม่สามารถ\n  - เห็นรายละเอียดของธุรกิจ\n  - เห็นรายละเอียดรายการ\n  - แลกของรางวัล\n  - โอนพอยท์ให้ผู้อื่น',
  follow: 'ติดตาม',
  subscribe_business: 'สมัครสมาชิก',
  refCode: 'รหัสอ้างอิง',
  changeLanguage: 'Change language',
  back: 'ย้อนกลับ',
  send_sms: 'ส่ง SMS',
  information: 'ข้อมูล',
  business_information: 'ข้อมูลธุรกิจ',
  copied_message_successfully: 'คัดลอกสำเร็จ',
  please_subscribe_points_redeem:
    'กรุณาสมัครสมาชิก\nเพื่อสะสมพอยท์ และแลกของรางวัล',
  please_subscribe_gift_voucher:
    'กรุณาสมัครสมาชิก\nเพื่อใช้งานบัตรกำนัล',
  please_subscribe_coupon:
    'กรุณาสมัครสมาชิก\nเพื่อใช้งานคูปอง',
  my_cards: 'บัตรสมาชิก',
  activated: 'ใช้งานแล้ว',
  activate: 'ใช้งาน',
  expired: 'หมดอายุ',
  show_detail: 'แสดงรายละเอียด',
  points_expire_detail: 'รายละเอียดพอยท์หมดอายุ',
  new: 'ใหม่',
  save_success: 'บันทึกสำเร็จแล้ว',

  'auth/code-expired': 'รหัส OTP หมดอายุ กรุณาขอรหัส OTP ใหม่',
  'auth/invalid-verification-code': 'รหัส OTP ไม่ถูกต้อง กรุณากรอกรหัส OTP ใหม่',
  'oauth2/invalid-phone-number': 'เบอร์โทรศัพท์ไม่ถูกต้อง',
  'oauth2/argument-phone-error': 'กรุณากรอกเบอร์โทรศัพท์',
  'oauth2/argument-otp-error': 'กรุณากรอกรหัส OTP',
  'oauth2/invalid-otp': 'รหัสยืนยันไม่ถูกต้อง กรุณากรอกรหัสยืนยันใหม่',
  'oauth2/otp-limit-exceeded': 'ขอรหัส OTP เกินจำนวนครั้งที่กำหนด\nกรุณาลองใหม่อีกครั้งใน 1 ชั่วโมง',
  'oauth2/operation-error': 'พบข้อผิดพลาด กรุณาติดต่อเจ้าหน้าที่',
  'oauth2/invalid-reference': 'รหัส OTP หมดอายุ กรุณาขอรหัส OTP ใหม่',
  'oauth2/reference-expired': 'รหัสยืนยันหมดอายุ กรุณาขอรหัสยืนยันใหม่',
  'oauth2/otp-too-many-retry': 'กรอกรหัส OTP ไม่ถูกต้องเกินจำนวนครั้งที่กำหนด\nกรุณาขอรหัส OTP ใหม่',
  'otp/request-quota-exceed': 'ขอรหัส OTP เกินจำนวนครั้งที่กำหนด\nกรุณาลองใหม่อีกครั้งใน 1 ชั่วโมง',
  'verify-otp-limit': 'ขอให้',
  'cannotConnectServer': 'ไม่สามารถเชื่อมต่อเซิร์ฟเวอร์',
  'maintenance': 'ระบบ Pointspot ปิดปรับปรุงชั่วคราว',
  'maintenanceDetail': 'ขออภัยในความไม่สะดวก',
  'reload': 'รีโหลด',
  loading: 'กำลังโหลด',
  'see_more': 'ดูทั้งหมด',
  'valid_time': 'ตั้งแต่',
  'promotion_coupon': 'คูปองโปรโมชั่น',
  'reward_coupon': 'คูปองที่แลกไว้',
  ecoupon_givePoint: 'แจกพอยท์',
  ecoupon_discount: 'ส่วนลด',
  ecoupon_free: 'ของรางวัล',
  ecoupon_redeem: 'แลกของรางวัล',
  ecoupon_remain: 'คงเหลือ',
  ecoupon_coupons: 'ใบ',
  'redeemed_valid': 'เริ่มใช้งานได้',
  coupon_redeemed_valid: 'เริ่มใช้งานได้',
  show_more: 'แสดงเพิ่มเติม',
  show_less: 'แสดงน้อยลง',
  business_notfound: 'ไม่พบธุรกิจ',
  page_notfound: 'ไม่พบหน้าที่คุณต้องการ',
  please_contact: 'กรุณาติดต่อ',
  'operation-error': 'พบข้อผิดพลาด กรุณาติดต่อเจ้าหน้าที่',
  coupon: 'คูปอง',
  coupon_expired: 'คูปองหมดอายุ',
  coupon_activated: 'คูปองใช้งานแล้ว',
  coupon_detail: 'รายละเอียดคูปอง',
  coupon_cancel: 'คูปองถูกยกเลิก',
  about_pointspot: 'เกี่ยวกับ Pointspot',
  edit_profile_success: 'แก้ไขข้อมูลส่วนตัวสำเร็จ',
  no_coupons: 'ไม่มีคูปอง',
  no_rewards: 'ไม่มีของรางวัล',
  continue_business: 'เข้าสู่ธุรกิจ',
  see_points_reward: 'ดูพอยท์สะสมและของรางวัล',
  satisfaction_review: 'ความพึงพอใจและความคิดเห็น',
  please_rate_satisfaction: 'กรุณาให้คะแนนความพึงพอใจ\nและความคิดเห็นของคุณ',
  thank_you_rating: 'ขอบคุณสำหรับคะแนนความพึงพอใจ',
  thank_you_rating_and_review: 'ขอบคุณสำหรับคะแนนความพึงพอใจ\nและการแสดงความคิดเห็น',
  logging_to: 'กำลังเข้าสู่ธุรกิจ',
  send_review: 'ส่งความคิดเห็น',
  review_placeholder_well: 'คำแนะนำเพิ่มเติมเพื่อพัฒนาบริการให้ดียิ่งขึ้น',
  review_placeholder: 'ช่วยให้คำแนะนำว่าเราควรปรับปรุงในเรื่องใด',
  expire_in: 'หมดอายุ',
  expire_on: 'หมดอายุในวันที่',
  will_expire_on: 'จะหมดอายุในวันที่',
  login_success: 'เข้าสู่ระบบสำเร็จ',
  delete: 'ลบ',
  choose: 'เลือก',
  verify_email: 'ยืนยันอีเมล',
  recaptcha_verify_invalid: 'ไม่สามารถเข้าสู่ระบบได้ กรุณาลองใหม่อีกครั้ง',

  /**
   * Main business list
   */
  business_subscribe: "ธุรกิจเป็นสมาชิก",
  business_recommended: "ธุรกิจที่รอการสมัครสมาชิก",

  // business header
  contact_phone: "โทรศัพท์",
  contact_facebook: "เฟซบุ๊ก",
  contact_messenger: "Messenger",
  contact_line: "LINE",
  contact_website: "เว็บไซต์",
  more: "เพิ่มเติม",

  notification: "แจ้งเตือน",
  please_check_information:
    "กรุณาตรวจสอบข้อมูลวันเกิดให้ถูกต้อง\nเนื่องจากมีผลกับโปรโมชั่นที่เกี่ยวข้องกับวันเกิด\n \nซึ่งจะไม่สามารถแก้ไขข้อมูลวันเกิดได้อีกหลังจากบันทึกข้อมูล",

  you_received_sms_notification_from: "ท่านได้รับข้อความแจ้งเตือนจากการใช้บริการ ",
  if_you_want_to_turnoff_notification_please_click:
    "หากต้องการยกเลิก คลิก",
  turnoff_notification: "ยกเลิกการรับข้อความแจ้งเตือน",
  of_business: "ของธุรกิจ",
  do_you_want_to_turnon_notification: "คุณต้องการเปิดแจ้งเตือน",
  do_you_want_to_turnoff_notification: "คุณต้องการปิดแจ้งเตือน",
  you_can_turn_back_on_from: "คุณสามารถเปิดแจ้งเตือน SMS อีกครั้งได้จาก",

  //order
  order_by: "เรียงตาม",
  order_date_desc: "วันที่ได้รับ ใหม่สุด",
  order_date_asc: "วันที่ได้รับ เก่าสุด",
  order_expire_desc: "วันหมดอายุ ใหม่สุด",
  order_expire_asc: "วันหมดอายุ เก่าสุด",

  // rewards
  points_used: 'พอยท์ที่ใช้แลก',
  redemption: "สิทธิ์",
  redeemable_up_to: "แลกได้ถึงวันที่",
  full_receive_redemption: "ใช้สิทธิ์ครบแล้ว",
  full_redemption: "สิทธิ์เต็มจำนวนแล้ว",
  redemption_balance: "จำนวนสิทธิ์คงเหลือ",
  available_redemption: "สามารถแลกได้",
  limited_quantity: "มีจำนวนจำกัด",
  day: "วัน",
  not_enough_point: "พอยท์ของคุณไม่เพียงพอ",
  not_enough_points: "พอยท์ของคุณไม่เพียงพอ ต้องการอีก",
  points_required: "จำนวนพอยท์ที่ต้องใช้",
  after_redeem: "หลังจากแลกพอยท์",
  reward_expire_in: "ของรางวัลจะหมดอายุในอีก",
  reward_not_found: "ไม่พบของรางวัล",
  reward_redemption_error: "เกิดข้อผิดพลาดในการแลกของรางวัล กรุณาลองใหม่",
  error: "เกิดข้อผิดพลาด กรุณาลองใหม่",
  pointspot_noti: "แจ้งเตือนจาก Pointspot",
  business_hold_text:
    "บัญชีสะสมพอยท์ของ $1 \nไม่สามารถใช้งานได้ในขณะนี้ กรุณาติดต่อร้านค้า",

  // gamble
  gamble_description: "พอยท์จะถูกเพิ่มขึ้นหรือลดลงตามผลลัพธ์ของเกมวงล้อ",
  error_gamble_full: "คุณได้ทำการเล่นเกมวงล้อไปแล้ว",
  gamble_close_time: "สิ้นสุดช่วงเวลาเปิดให้เล่นเกมแล้ว",
  not_enough_point_gamble: "พอยท์ของคุณไม่พอสำหรับการเล่นเกม",
  lucky_wheel: "เกมวงล้อ",
  lucky_draw_wheel: "เล่นเกมวงล้อลุ้นรับพอยท์เพิ่ม",
  lucky_draw_wheel_guide:
    "แนะนำ : เมื่อทำการกดหมุน วงล้อจะหมุนและสุ่มหยุด ณ พื้นที่ใดพื้นที่หนึ่งของวงล้อ ผลลัพธ์ที่ได้คือตำแหน่งที่หมุดชี้อยู่",
  game_earn_points: "คุณได้รับพอยท์จากเกมวงล้อ",
  game_lose_points: "คุณเสียพอยท์จากเกมวงล้อ",
  result_zero: "คุณไม่ได้รับพอยท์จากเกมวงล้อ\nลองอีกครั้งในภายหลัง",
  earn_points_more: "คุณได้รับพอยท์เพิ่ม",
  earn_game: 'ได้พอยท์จากการเล่นเกม',
  lose_points: "คุณถูกลดพอยท์",
  game_deal: "คุณไม่ได้รับพอยท์เพิ่ม",
  spin_draw: "หมุนวงล้อ",
  try_again_later: "ลองอีกครั้งในภายหลัง",
  congratulations: "ยินดีด้วย",
  try_again: "ลองใหม่โอกาสหน้า",

  // swipe and pincode
  activate_admin_only: 'ใช้งานคูปอง \n(สำหรับผู้ดูแลระบบเท่านั้น)',
  activate_coupon: 'ใช้งานคูปอง',
  please_try_again: 'กรุณาลองใหม่อีกครั้ง',
  fill_in_pincode: 'กรอกรหัสผ่าน',
  pincode_incorrect: 'รหัสไม่ถูกต้อง',
  swipe_note: 'ข้อความอ้างอิง',
  coupon_activate_once: 'คูปองนี้ใช้ได้เพียงครั้งเดียวเท่านั้น',
  activated_repeatedly: 'คูปองที่ใช้งานไปแล้วจะไม่สามารถใช้งานได้อีก',
  coupon_count_activate: 'จำนวนคูปองที่ต้องการใช้',
  coupon_remaining: 'คูปองคงเหลือ $x ใบ',
  show_qr_code: 'แสดง QR Code',

  // member card
  member_card: "บัตรสมาชิก",
  to_privilege: "แตะที่บัตรเพื่อดูสิทธิประโยชน์",
  privilege_card: "สิทธิประโยชน์",
  member_card_expired: "บัตรสมาชิกหมดอายุ",
  member_card_cancel: "บัตรสมาชิกถูกยกเลิก",
  member_card_info: 'ข้อมูลบัตรสมาชิก',
  member_card_upgrade: 'การเลื่อนระดับบัตรสมาชิก',
  calculate_points_earned: 'คำนวณพอยท์ที่ได้รับจากธุรกิจ',
  months: 'เดือนล่าสุด',

  // change mobile phone
  change_phone_number: "เปลี่ยนหมายเลขโทรศัพท์",
  "phone-number-used": "หมายเลขโทรศัพท์นี้ถูกใช้ลงทะเบียนในระบบแล้ว",
  if_question_please_contact: "หากมีคำถาม กรุณาติดต่อ",
  current_phone_number: "หมายเลขปัจจุบัน",
  new_phone_number: "หมายเลขใหม่",
  confirm: "ยืนยัน",
  verify_otp_to_change_phone: "ยืนยันรหัส OTP เพื่อเปลี่ยนหมายเลขโทรศัพท์",
  phone_number: "หมายเลขโทรศัพท์",
  change_phone_number_success: "เปลี่ยนหมายเลขโทรศัพท์สำเร็จ",
  otp_request: "ขอรหัส OTP",
  loading_data: "กำลังโหลดข้อมูลใหม่",

  // giveaway coupon
  giveaway_coupon: "สิทธิพิเศษฟรี",
  giveaway_coupon_code: "รหัสคูปองแจกฟรี",
  get_coupon: "รับคูปอง",
  get_up_to: "รับคูปองได้ถึงวันที่",
  activate_up_to: "ใช้งานคูปองได้ถึงวันที่",
  coupon_out: "คูปองหมด",
  get_coupon_full_member_limit: "รับคูปองครบตามจำนวนแล้ว",
  available_get: "รับได้อีก",
  coupon_balance: "คูปองคงเหลือ",
  get_question: "ยืนยันการรับคูปอง",
  after_get: "หลังการกดรับคูปอง",
  coupon_expire_in: "คูปองจะหมดอายุในอีก",
  coupon_expire_today: "คูปองจะหมดอายุภายในวันนี้",
  coupon_not_found: "ไม่พบคูปอง",
  get_coupon_error: "เกิดข้อผิดพลาดในการรับคูปอง กรุณาลองใหม่",

  // address
  address: "ที่อยู่",
  address_name: "ชื่อสถานที่",
  address_line: "ที่อยู่",
  district: "เขต / อำเภอ",
  sub_district: "แขวง / ตำบล",
  _district: "เขต",
  _sub_district: "แขวง",
  province: "จังหวัด",
  postal_code: "รหัสไปรษณีย์",
  country: "ประเทศ",
  address_placeholder: "เลขที่, ซอย, แยก, ถนน",
  add_address: "เพิ่มที่อยู่",
  edit_address: "แก้ไขที่อยู่",
  receiver_name: "ชื่อผู้รับ",
  home: "บ้าน",
  address_name_placeholder: "เช่น บ้าน",
  not_found_address: "ไม่มีรายการที่อยู่",
  delete_address: "ลบที่อยู่",
  delete_address_confirm: "คุณต้องการลบที่อยู่นี้ใช่ไหม ?",
  delivery_address: "ที่อยู่ในการจัดส่ง",

  // Rwards receiving
  rewards_receiving: "การรับของรางวัล",
  by_self: "รับด้วยตนเอง",
  by_delivery: "รับโดยการจัดส่ง",
  by_self_or_delivery: "รับด้วยตนเองหรือจัดส่ง",
  prepare_reward: "อยู่ระหว่างการเตรียมของรางวัลเพื่อจัดส่ง",
  delivery_reward: "อยู่ระหว่างจัดส่งของรางวัล",
  delivered_reward: "จัดส่งของรางวัลสำเร็จ",
  prepare: "จัดเตรียม",
  delivery: "จัดส่ง",
  delivered: "จัดส่งแล้ว",
  spot_redemption: "รับที่จุดแลกรับของรางวัล",
  spot_redemption_receiving:
    "กรุณาแสดงคูปอง ณ จุดแลกรับของรางวัล\nภายใน 5 นาทีหลังจากกดยืนยัน",
  time_out: "หมดเวลา",
  minute: "นาที",
  not_enough_point_error: "พอยท์ไม่เพียงพอ",
  spot_redemption_is_active: "มีคูปองที่พร้อมใช้งานอยู่",
  redemption_code: "รหัสแลกของรางวัล",
  reward_redemption_coupon: "คูปองแลกของรางวัล",
  reward_redemption_code: "รหัสแลกของรางวัล",
  submit_redeem: "ยืนยันแลกของรางวัล",
  time_expire_in: "หมดอายุในอีก",
  please_show_this_coupon: 'กรุณาแสดงคูปองนี้กับเจ้าหน้าที่',
  to_admin_to_activate: 'เพื่อรับสิทธิ์',

  // product
  product_detail: "รายละเอียดสินค้า",
  product_item_total: "รายการสินค้าทั้งหมด",
  product_deleted: "สินค้าถูกลบ",
  item: "รายการ",
  product_list: "รายการสินค้า",
  amount: "จำนวน",
  total_amount: "จำนวนรวม",
  sum_total_points: "จำนวนพอยท์",
  points_earned: "พอยท์ที่ได้รับ",
  "some-product-archived": "สินค้าบางรายการมีการเปลี่ยนแปลง",

  // friend get friends
  friend_get_friends: "เพื่อนชวนเพื่อน",
  get_friends: "ชวนเพื่อน",
  get_friends_title: "เชิญเพื่อนมาเป็นสมาชิกของธุรกิจ",
  inviter_reward: "รางวัลของคุณ",
  invitee_reward: "รางวัลของเพื่อน",
  gift_voucher: "บัตรกำนัล",
  friend_get_friends_period: "ระยะเวลากิจกรรมเพื่อนชวนเพื่อน",
  invitee_subscribed_detail: "เพื่อนของคุณต้องสมัครสมาชิกธุรกิจนี้เป็นครั้งแรก",
  invitee_active_detail: "เพื่อนของคุณต้องใช้งานคูปอง",
  invite_limit_detail: "สามารถเชิญเพื่อนได้สูงสุด $1 คน",
  message_invite_friends:
    "คำเชิญจากเพื่อน สมัครสมาชิกธุรกิจ $1 ครั้งแรก รับรางวัลทันที คลิก $2",
  friend_get_friends_detail: "รายละเอียดเพิ่มเติม",
  your_invite_code: "โค้ดเชิญของคุณ",
  invite_card: "บัตรเชิญ",
  get_friends_title_card: "คุณได้รับเชิญเข้าร่วมเป็นสมาชิกของ",
  scan_qr_code_to_subscribe:
    "สแกน QR Code สมัครสมาชิกธุรกิจ\nรับของรางวัลทันที",
  invite_refer: "ได้รับเชิญจาก (เบอร์โทรศัพท์/Pointspot ID)",
  invite_count: "เชิญแล้วทั้งหมด $1/$2 คน",
  invite_by_message: "ข้อความเชิญเพื่อน",
  invite_by_card: "บัตรเชิญเพื่อน",

  // line liff
  id: "ไอดี",
  liff_title: "รายการ business ที่ login ด้วย LINE LIFF app",
  confirm_remove_liff: "คุณแน่ใจหรือไม่ว่าต้องการลบการเชื่อมต่อ LINE ธุรกิจนี้",
  confirm_remove_liff_title: "ลบการเชื่อมต่อ LINE",
  remove_line_btn: "ลบการเชื่อมต่อ",
  liff_toast_success: "ลบการเชื่อมต่อสำเร็จ",
  liff_toast_fail: "ลบการเชื่อมต่อไม่สำเร็จ",

  received_gift_voucher: 'คุณได้รับบัตรกำนัล',
  received_coupon: 'คุณได้รับคูปอง',

  //Collect Point
  select_items: "เลือกรายการ",
  select_items_more: "เลือกรายการเพิ่มเติม",
  select: 'เลือก',
  product_items: "ชื่อรายการ",
  bath: "บาท",
  image_attachment: "แนบรูปภาพใบเสร็จ",
  select_image: "เลือกรูปภาพ",
  select_image_more: "เลือกรูปภาพเพิ่มเติม",
  send_request_successfully: "ส่งรายการสำเร็จ",
  send_request_unsuccessfully: "ส่งรายการไม่สำเร็จ",
  product: "สินค้า",
  collect_point_item: 'รายการ',
  recently_collect: "ประวัติรายการ",
  earn: "ได้รับ",
  collect_point : "แลกรับพอยท์",
  category: "หมวดหมู่",
  amount_value : "มูลค่า",
  slip : "ตัวอย่าง ใบเสร็จรับเงิน",
  sent: "รออนุมัติ",
  approve : "อนุมัติ",
  reject : "ปฏิเสธ",
  submit_button : "ส่ง",
  please_enter_correct_amount : 'กรุณาใส่เฉพาะตัวเลข 0-9',
  file_types : 'รองรับไฟล์ .jpg และ .png',
  max_files : 'สูงสุด 5 ไฟล์',
  max_items : 'เลือกได้สูงสุด 5 รายการ',
  error_limit_files : 'แนบรูปภาพได้ไม่เกิน 5 รูป',
  oversize: 'จำกัดขนาดไฟล์ไม่เกิน 30 MB',
  no_point_collected : 'ไม่พบรายการ',
  please_select_valid_file: 'กรุณาเลือกไฟล์ที่ถูกต้อง',
  from_collect_points: 'พอยท์จากแลกรับพอยท์',
  no_items: 'ไม่พบรายการ',
  reference: 'ข้อความอ้างอิง',
  example_remark_collect_points: 'ตัวอย่าง ชื่อแคมเปญ หรือรายละเอียดเพิ่มเติม',
  edit_collect_points: 'แก้ไขรายการ',
  edit_collect_points_header: 'แก้ไขรายการแลกรับพอยท์',
  edit_date: 'แก้ไขล่าสุด',
  remark_from_business : 'หมายเหตุจากธุรกิจ',
  see_more_comment: 'ดูเพิ่มเติม',
  see_less_comment: 'ดูน้อยลง',
  see_details: 'ดูรายละเอียด',
  collect_points_details: 'รายการแลกรับพอยท์',
  take_a_photo: 'ถ่ายรูป',
  take_more_photos: 'ถ่ายรูปเพิ่มเติม',
  take_a_photo_again: 'ถ่ายรูปใหม่อีกครั้ง',
  select_this_photo: 'เลือกรูปนี้',
  select_camera: 'เลือกกล้องถ่ายรูป',
  opening_camera: 'กำลังเปิดกล้อง',

  //verifyEmail
  verification_sent : 'กรุณากรอกรหัสยืนยันที่ถูกส่งไปยังอีเมล',
  verify_ref: 'รหัสอ้างอิง',
  verify_button: 'ยืนยัน',
  no_email : "ส่งรหัสอีกครั้ง",
  send_email_again: 'ส่งรหัสอีกครั้ง',
  skip_verify_email: 'ไว้ทีหลัง',
  for_verify: 'เพื่อยืนยันอีเมล ระบบจะส่งรหัสยืนยันไปยัง',

  'parsePhoneNumber_too_short': 'เบอร์โทรศัพท์ไม่ถูกต้อง',
  'auth/invalid-phone-number': 'เบอร์โทรศัพท์ไม่ถูกต้อง',
  email_already_exist: 'อีเมลนี้มีในระบบแล้ว',
  email_incorrect : 'อีเมลไม่ถูกต้อง',
  please_enter_OTP : 'กรุณาใส่รหัส OTP',
  verify_email_successfully : 'ยืนยันอีเมลสำเร็จ',
  benefit_email: 'เพื่อรับอีเมลแจ้งเตือนเมื่อใช้พอยท์และสรุปพอยท์รายเดือน',
  please_enter_email: 'กรุณากรอกอีเมล',
  send_otp_via_email: 'ส่งรหัสทางอีเมล',
  send_otp_via_email_again: 'ส่งรหัสอีกครั้งทางอีเมล',
  please_enter_the_email_registered_with_pointspot : 'กรุณากรอก Email ที่ลงทะเบียนไว้กับ Pointspot',
  unable_to_login: 'ไม่สามารถเข้าสู่ระบบได้',
  select_account: 'เลือกบัญชีที่ต้องการเข้าสู่ระบบ',
  email_use_login: 'ใช้ในกรณีไม่สามารถใช้เบอร์โทรได้',

  //points
  pull: 'ดึง',
  history: 'ดูประวัติ',

  no_unsubscribed_businesses : 'ไม่มีธุรกิจที่เลิกติดตาม',
  continue: 'ดำเนินการต่อ',
  required: 'กรุณากรอกข้อมูล',
  enter_information: 'กรุณากรอกข้อมูลเพิ่มเติม',
  member_info: 'ข้อมูลสมาชิก',

  //setting notification
  setting_notification: 'ตั้งค่าการแจ้งเตือน',
  setting_email: 'การแจ้งเตือนทางอีเมล',
  points_summary: 'สรุปพอยท์คงเหลือรายเดือน',
  noti_of_transaction: 'แจ้งเตือนเมื่อใช้พอยท์',
  pointspot_noti_setting: 'การแจ้งเตือน Pointspot',

  //ModalSubscribe
  loyalty_platform: 'ระบบบัตรสมาชิกสะสมแต้ม',
  allow_Pointspot: 'อนุญาตให้ Pointspot',
  allow_to_send:'ส่งข้อมูลของคุณให้ธุรกิจ',
  by_granting_Pointspot_permission:'เมื่ออนุญาตให้ Pointspot ส่งข้อมูล ชื่อ เบอร์โทร\nเพศ อายุ อีเมล ที่อยู่ ของคุณให้ธุรกิจ\nคุณจะได้รับโปรโมชัน และการติดต่อสื่อสาร\nที่เข้ากับความต้องการของคุณจากธุรกิจ',
  allow: 'อนุญาต',

  received_point_transfer: 'ได้รับโอนพอยท์',

  //Line
  line_display_name: 'ชื่อที่แสดงใน LINE',
  business_subscribed_line: 'ธุรกิจที่ติดตามผ่าน LINE',

  please_enter_information : 'กรุณากรอกข้อมูลเพิ่มเติม',
  save_data_successfully: 'บันทึกข้อมูลสำเร็จ',
  save_data_unsuccessfully: 'บันทึกข้อมูลไม่สำเร็จ',
  
  //UserInfoForBiz
  info_shared_business: 'ข้อมูลที่คุณให้กับธุรกิจ',
  later: 'ไว้ภายหลัง',
  edit_information: 'แก้ไขข้อมูล',

  //NoLoyaltyAccount
  you_do_not_have_loyalty_account: 'คุณยังไม่มีบัญชีสะสมพอยท์',
  subscribe_business_to_start_collecting_points: 'สมัครสมาชิกธุรกิจเพื่อเริ่มสะสมพอยท์',
  easy_for_customer_to_collect_point: 'ลูกค้าสะสมแต้มง่าย เพียงบอกเบอร์โทรศัพท์',
  receive_points_via_SMS: 'รับพอยท์ผ่าน SMS ไม่ต้องพกบัตรสะสมแต้ม',
}
  